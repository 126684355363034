import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Component({
  standalone: true,
  template: '',
})
export class SubscriptionsComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  protected subscriptionsMap = new Map<string, Subscription>();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptionsMap.forEach((subscription) => subscription.unsubscribe());
  }
}
